import React from 'react';
import './figma.css';

function FigmaPrototype() {
  return (
    <section className="figma-section mb-10" >
      <h1 className='text-5xl md:text-7xl montserrat-bold mb-10'><span className="block sm:inline ">Projects</span></h1>
    <iframe id="work" className="figma rounded-3xl" title='Figma' frameborder="0" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FGqBvgKIJMCZmtWsszwgnBI%2FPortfolio-Design%3Fpage-id%3D0%253A1%26node-id%3D1-595%26node-type%3DFRAME%26viewport%3D-1657%252C850%252C0.38%26t%3D9vleu11SbcFVgYvU-1%26scaling%3Dscale-down-width%26content-scaling%3Dfixed%26starting-point-node-id%3D1%253A595%26hotspot-hints%3D0%26hide-ui%3D1" allowfullscreen></iframe>
    
    </section>
  );
}

export default FigmaPrototype;
